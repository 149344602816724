import { FC } from "react";
import {
	Buttons
} from "../../assets/index.styles";
import Icon from "../../assets/icons";
// import { NotificationIndicator } from "./NotificationIndicator";
import { Box } from "@twilio-paste/core";

interface NavButtonType {
	icon: string;
	view?: string;
	title: string;
	onClick: () => void;
}

export const NavButton: FC<NavButtonType> = ({ icon, view, title, onClick }) => {
	return (
		<Buttons onClick={onClick}>
			<Box display="flex" columnGap="space40">
				<Icon icon={icon} view={view}/>
				{title}
			</Box>
			{/* {title === "Chat" ? <NotificationIndicator /> : null} */}
		</Buttons>
	);
};